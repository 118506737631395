<template>
    <base-scheda models-name="workspace">

        <template #header="{ model }">
            <scheda-workspace-header :model="model"></scheda-workspace-header>
        </template>

        <template #titolo="{ model }">
            <scheda-workspace-titolo :model="model"></scheda-workspace-titolo>
        </template>

        <template #generale="{ model }">
            <scheda-workspace-generale :model="model"></scheda-workspace-generale>
        </template>

    </base-scheda>
</template>

<script>
    import BaseScheda from '@/components/aaaProject/scheda/BaseScheda';
    import SchedaWorkspaceHeader from '@/components/secure/workspace/components/SchedaWorkspaceHeader';
    import SchedaWorkspaceTitolo from '@/components/secure/workspace/components/SchedaWorkspaceTitolo';
    import SchedaWorkspaceGenerale from '@/components/secure/workspace/components/SchedaWorkspaceGenerale';

    export default {
        name: 'SchedaWorkspace',
        components: {SchedaWorkspaceGenerale, SchedaWorkspaceTitolo, SchedaWorkspaceHeader, BaseScheda}
    }
</script>

<style scoped>

</style>
