<template>
    <base-scheda-generale :model="model" :items="items"></base-scheda-generale>
</template>

<script>
    import BaseSchedaGenerale from '@/components/aaaProject/scheda/BaseSchedaGenerale';
    import moment from '@/moment';

    export default {
        name: 'SchedaWorkspaceGenerale',
        components: {BaseSchedaGenerale},
        props: {model: Object},
        computed: {
            items: function () {
                return [
                    {label: 'Nome', data: this.model.NOME},
                    {label: 'Utenze Associate', data: this.model.UT_COUNT},
                    {label: 'Clienti Associati', data: this.model.CL_COUNT},
                    {label: 'Eventi Associati', data: this.model.EV_COUNT},
                    {label: 'Visualizzazioni', data: this.model.VISUALIZZAZIONI},
                    {
                        label: 'Disattivazione Manuale',
                        data: this.model.DATA_DISATTIVAZIONE ? moment.toDate(this.model.DATA_DISATTIVAZIONE) : 'MAI'
                    }
                ]
            }
        }
    }
</script>

<style scoped>

</style>
