<template>
    <base-scheda-header models-name="workspace"
                        :model="model"
                        modelRef="il workspace"
    ></base-scheda-header>
</template>

<script>
    import BaseSchedaHeader from '@/components/aaaProject/scheda/BaseSchedaHeader';

    export default {
        name: 'SchedaWorkspaceHeader',
        components: {BaseSchedaHeader},
        props: {model: Object}
    }
</script>

<style scoped>

</style>
